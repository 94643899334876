
'use strict';

/**
 * @param {number} n
 * @param {string[]} forms
 * @param {string} accForOne
 */

const getPolishAccusativeForm = (n, forms, accForOne) => {
	const [form1, form2_4, form5plus] = forms;

	if (n === 1) {
		return accForOne;
	}

	const mod100 = n % 100;
	const mod10 = n % 10;

	if (mod100 >= 11 && mod100 <= 19) {
		return form5plus;
	}

	switch (mod10) {
		case 2:
		case 3:
		case 4:
			return form2_4;
		default:
			return form5plus;
	}
};

const getPolishTimeLabel = (unit, n) => {
	switch (unit) {
		case 'day':
			return getPolishAccusativeForm(n, ['dzień', 'dni', 'dni'], 'dzień');

		case 'hour':
			return getPolishAccusativeForm(
				n,
				['godzina', 'godziny', 'godzin'],
				'godzinę'
			);

		case 'minute':
			return getPolishAccusativeForm(
				n,
				['minuta', 'minuty', 'minut'],
				'minutę'
			);

		case 'second':
			return getPolishAccusativeForm(
				n,
				['sekunda', 'sekundy', 'sekund'],
				'sekundę'
			);

		default:
			return '';
	}
};

const pad = (num) => (num < 10 ? '0' + num : String(num));

const getTimeRemaining = (now, targetDate) => {
	const diff = targetDate - now;
	if (diff <= 0) {
		return { days: 0, hours: 0, minutes: 0, seconds: 0, finished: true };
	}

	const totalSeconds = Math.floor(diff / 1000);
	const days = Math.floor(totalSeconds / (3600 * 24));
	const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
	const minutes = Math.floor((totalSeconds % 3600) / 60);
	const seconds = totalSeconds % 60;

	return { days, hours, minutes, seconds, finished: false };
};

const updateText = (element, newValue) => {
	if (element.textContent !== newValue) {
		element.textContent = newValue;
		if (element.classList.contains('hero__countdown-digits')) {
			element.classList.add('changed');
			setTimeout(() => element.classList.remove('changed'), 400);
		}
	}
};

const updateCountdown = (now, targetDate, elements) => {
	const { days, hours, minutes, seconds, finished } = getTimeRemaining(
		now,
		targetDate
	);

	const daysDigits = pad(days);
	const hoursDigits = pad(hours);
	const minutesDigits = pad(minutes);
	const secondsDigits = pad(seconds);

	const dayLabel = getPolishTimeLabel('day', finished ? 0 : days);
	const hourLabel = getPolishTimeLabel('hour', finished ? 0 : hours);
	const minuteLabel = getPolishTimeLabel('minute', finished ? 0 : minutes);
	const secondLabel = getPolishTimeLabel('second', finished ? 0 : seconds);

	updateText(elements.daysDigitsEl, daysDigits);
	updateText(elements.daysLabelEl, dayLabel);

	updateText(elements.hoursDigitsEl, hoursDigits);
	updateText(elements.hoursLabelEl, hourLabel);

	updateText(elements.minutesDigitsEl, minutesDigits);
	updateText(elements.minutesLabelEl, minuteLabel);

	updateText(elements.secondsDigitsEl, secondsDigits);
	updateText(elements.secondsLabelEl, secondLabel);
};

const initCountdown = () => {
	const targetDate = new Date(2025, 4, 16, 18, 0, 0);

	const daysDigitsEl = document.querySelector('.hero__countdown-digits--days');
	const daysLabelEl = document.querySelector('.hero__countdown-label--days');
	const hoursDigitsEl = document.querySelector(
		'.hero__countdown-digits--hours'
	);
	const hoursLabelEl = document.querySelector('.hero__countdown-label--hours');
	const minutesDigitsEl = document.querySelector(
		'.hero__countdown-digits--minutes'
	);
	const minutesLabelEl = document.querySelector(
		'.hero__countdown-label--minutes'
	);
	const secondsDigitsEl = document.querySelector(
		'.hero__countdown-digits--seconds'
	);
	const secondsLabelEl = document.querySelector(
		'.hero__countdown-label--seconds'
	);

	if (
		!daysDigitsEl ||
		!daysLabelEl ||
		!hoursDigitsEl ||
		!hoursLabelEl ||
		!minutesDigitsEl ||
		!minutesLabelEl ||
		!secondsDigitsEl ||
		!secondsLabelEl
	) {
		console.warn('Brak wymaganych elementów odliczania w DOM!');
		return;
	}

	const elements = {
		daysDigitsEl,
		daysLabelEl,
		hoursDigitsEl,
		hoursLabelEl,
		minutesDigitsEl,
		minutesLabelEl,
		secondsDigitsEl,
		secondsLabelEl,
	};

	const tick = () => {
		const now = new Date();
		updateCountdown(now, targetDate, elements);
	};

	tick();
	setInterval(tick, 1000);
};

document.addEventListener('DOMContentLoaded', initCountdown);

document.addEventListener('DOMContentLoaded', () => {
	const faqItems = document.querySelectorAll('.faq__item');

	faqItems.forEach((item) => {
		const btn = item.querySelector('.faq__toggle');
		const answer = item.querySelector('.faq__item-answer');
		const icon = btn.querySelector('.faq__toggle-anchor');
		// Ustalamy stan otwarcia na podstawie obecności klasy na elemencie
		let isOpen = item.classList.contains('faq__item--active');

		// Ustawienia początkowe
		if (isOpen) {
			const scrollHeight = answer.scrollHeight + 'px';
			answer.style.maxHeight = scrollHeight;
			answer.style.opacity = '1';
			icon.classList.add('active');
			btn.setAttribute('aria-expanded', 'true');
		} else {
			answer.style.maxHeight = '0';
			answer.style.opacity = '0';
			btn.setAttribute('aria-expanded', 'false');
		}

		btn.addEventListener('click', () => {
			if (!isOpen) {
				// Rozwijanie
				const scrollHeight = answer.scrollHeight + 'px';
				answer.style.maxHeight = scrollHeight; // Ustaw pełną wysokość treści
				answer.style.opacity = '1'; // Ustaw pełną widoczność
				item.classList.add('faq__item--active');
				icon.classList.add('active');
				btn.setAttribute('aria-expanded', 'true');
				isOpen = true;
			} else {
				// Zwinięcie
				answer.style.maxHeight = '0';
				answer.style.opacity = '0';
				item.classList.remove('faq__item--active');
				icon.classList.remove('active');
				btn.setAttribute('aria-expanded', 'false');
				isOpen = false;
			}
		});
	});
});
